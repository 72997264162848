import React, {useRef, useState, useEffect} from "react";

const ContactForm = props => {

    const [sent, setSent] = useState(false);
    const [sending, setSending] = useState(false);

    const contactForm = useRef(null);
    const name = useRef(null);
    const surname = useRef(null);
    const email = useRef(null);
    const tel = useRef(null);
    const message = useRef(null);
    const modalMessageForm = useRef(null);

    const validateEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        if (sent) {
            let timerOne = setTimeout(() => {
                modalMessageForm.current.classList.add('fadeOut');
            }, 2000)

            let timerTwo = setTimeout(() => {
                setSent(false);
            }, 3000)

            return () => {
                clearTimeout(timerOne);
                clearTimeout(timerTwo);
            }
        }
    }, [sent])

    const handleSubmit = async (event) => {

        event.preventDefault();
        name.current.parentElement.classList.remove('error');
        surname.current.parentElement.classList.remove('error');
        email.current.parentElement.classList.remove('error');
        tel.current.parentElement.classList.remove('error');
        message.current.parentElement.classList.remove('error');

        let valid = true;
        if (name.current.value == '') {
            name.current.parentElement.classList.add('error');
            valid = false;
        }
        if (surname.current.value == '') {
            surname.current.parentElement.classList.add('error');
            valid = false;
        }
        if (!validateEmail(email.current.value)) {
            email.current.parentElement.classList.add('error');
            valid = false;
        }
        if (tel.current.value == '') {
            tel.current.parentElement.classList.add('error');
            valid = false;
        }

        if (message.current.value == '') {
            message.current.parentElement.classList.add('error');
            valid = false;
        }

        if (valid) {
            setSending(true);
            const formdata = new FormData(event.target);

            const response = await fetch('/api/contact', {
                'method': 'POST',
                body: formdata
            });
            const data = await response.json();
            if (data) {
                let msg = data.msg;
                let code = data.code;
                if (code == 1) {
                    contactForm.current.reset();
                    setSent(true);
                    setSending(false);

                } else {
                    alert(msg);
                }
            }

        }
    }

    return (
        <>
            <form className="rd-mailform" data-form-type="contact"
                  method="post" onSubmit={handleSubmit} ref={contactForm}>
                <div className="row row-30">
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <input ref={name} className="form-input" id="feedback-3-first-name" type="text"
                                   name="name" placeholder="Meno" />
                            <span className="form-validation">Meno je povinné!</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <input ref={surname} className="form-input" id="feedback-3-last-name" type="text"
                                   name="surname" placeholder="Priezvisko" />
                            <span className="form-validation">Priezvisko je povinné!</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <input ref={email} className="form-input" id="feedback-3-email" type="email"
                                   name="email" placeholder="Email" />
                            <span className="form-validation">Email je povinný!</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-wrap">
                            <input ref={tel} className="form-input" id="feedback-3-phone" type="text"
                                   name="phone" placeholder="Telefón" />
                            <span className="form-validation">Tel. číslo je povinné!</span>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-wrap">
                            <textarea ref={message} className="form-input"
                                      id="feedback-3-message"
                                      name="text"
                                      placeholder="Správa"></textarea>

                            <span className="form-validation">Správa je povinná!</span>
                        </div>
                    </div>
                </div>

                <div className="row row-offset-5">
                    <div className="col-md-6">
                        <button disabled={sending} className="button button-ebony-clay-outline button-block"
                                type="submit">{sending ? 'Odosielanie...' : 'Odoslať'}
                        </button>
                    </div>
                </div>

            </form>
            {sent &&
            <section className="modal-message-form animated fadeIn" ref={modalMessageForm}>
                <div className="circle-loader load-complete">
                    <div className="checkmark draw"></div>
                </div>
                <h2>Ďakujeme</h2>
            </section>
            }
        </>
    );

}


export default ContactForm;
