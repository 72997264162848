import React from 'react';
import ReactDom from 'react-dom';
import ContactForm from './components/ContactForm'

document.addEventListener("DOMContentLoaded", () => {


    let doc = document;
    let body = doc.querySelector('body');
    let html = doc.querySelector('html');

    let contactFormE = doc.querySelector('#contact-form');
    console.log(contactFormE);
    if(contactFormE) {
        ReactDom.render(<ContactForm />, contactFormE);
    }

    imgComparator();
});

function calcHeight(item) {
    let width = item.offsetWidth;
    // item.querySelector(".wrapper").style.height = width / 2.00626959248 + "px";
}

// Let's use this function
function scrollIt(x, item) {
    let transform = Math.max(0, (Math.min(x, item.querySelector('.wrapper').offsetWidth)));
    item.querySelector('.after').style.width = transform - 2 + "px";
    item.querySelector('.scroller').style.left = transform -2 + "px";
}
function imgComparator() {
    let imgComparator = document.querySelectorAll(".img-comparator");

    if (imgComparator.length > 0) {
        imgComparator.forEach((item) => {
            let active = false;
            calcHeight(item);
            let scroller = item.querySelector(".scroller");
            // First we'll have to set up our event listeners
            // We want to watch for clicks on our scroller
            scroller.addEventListener("mousedown", function () {
                active = true;
                // Add our scrolling class so the scroller has full opacity while active
                scroller.classList.add("scrolling");
            });
            // We also want to watch the body for changes to the state,
            // like moving around and releasing the click
            // so let's set up our event listeners
            document.body.addEventListener("mouseup", function () {
                active = false;
                scroller.classList.remove("scrolling");
            });
            document.body.addEventListener("mouseleave", function () {
                active = false;
                scroller.classList.remove("scrolling");
            });

            // Let's figure out where their mouse is at
            document.body.addEventListener("mousemove", function (e) {
                if (!active) return;
                // Their mouse is here...
                let x = e.pageX;
                // but we want it relative to our wrapper
                x -= item.querySelector(".wrapper").getBoundingClientRect().left;
                // Okay let's change our state
                scrollIt(x, item);
            });

            item.querySelector(".after").style.backgroundSize =
                item.querySelector(".wrapper").clientWidth + "px 100%";
            item.querySelector(".before").style.backgroundSize =
                item.querySelector(".wrapper").clientWidth + "px 100%";

            // Let's set our opening state based off the width,
            // we want to show a bit of both images so the user can see what's going on
            scrollIt(item.querySelector(".wrapper").clientWidth / 2, item);

            // And finally let's repeat the process for touch events
            // first our middle scroller...
            scroller.addEventListener("touchstart", function () {
                active = true;
                scroller.classList.add("scrolling");
            });

            document.body.addEventListener("touchend", function () {
                active = false;
                scroller.classList.remove("scrolling");
            });
            document.body.addEventListener("touchmove", function (e) {
                if (!active) return;
                // Their mouse is here...
                let x = e.touches[0].pageX;
                // but we want it relative to our wrapper
                x -= item.querySelector(".wrapper").getBoundingClientRect().left;
                // Okay let's change our state
                scrollIt(x, item);
            });
            document.body.addEventListener("touchcancel", function () {
                active = false;
                scroller.classList.remove("scrolling");
            });
            window.addEventListener("resize", function () {
                calcHeight(item);
            });
        });
    }
}